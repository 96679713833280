import { useApi } from "~/composables/shared/useApi";
import { useSafe } from "~/composables/shared/useSafe";
import type { Result } from "@frontend/ui-kit/src/types/result";
import { ROUTES } from '~/constants/routes';
import type { AcceptanceStatus, IAcceptancesRealty } from '~/layers/settlement/types/realty';
import { AcceptanceStatuses } from '~/layers/settlement/constants/statuses';

export const useSettlementList = () => {
    const { tryAction } = useSafe();
    const { post } = useApi();

    const { t } = useI18n();

    /** Возвращает список ОН для клиента */
    const getRealtyClientList = async () : Promise<Result<IAcceptancesRealty[]>> => {
        return await tryAction(async () => {
            const config = useRuntimeConfig();
            return await post<IAcceptancesRealty[]>(config.public.realtyApiBaseUrl + '/realty/client/list', {});
        });
    }

    /**
    * Показываем ли кнопку в зависимости от статуса
    * @param status Статус приемки
    */
    const isButtonShow = (status: AcceptanceStatus): boolean => {
        switch (status) {
        case AcceptanceStatuses.Participant:
        case AcceptanceStatuses.CheckPersonalData:
        case AcceptanceStatuses.ChooseDate:
        case AcceptanceStatuses.NeedPrepareDocuments:
        case AcceptanceStatuses.Meeting:
        case AcceptanceStatuses.ResultWithRemarks:
        case AcceptanceStatuses.FailResult:
        case AcceptanceStatuses.SuccessResult:
            return true;
        case AcceptanceStatuses.Undefined:
        case AcceptanceStatuses.Draft:
        default:
            return false; 
        }
    };

    /**
    * На основе статуса определяем текст кнопки
    * @param status Статус приемки
    */
    const getButtonText = (status: AcceptanceStatus): string => {
        switch (status) {
        case AcceptanceStatuses.Participant:
        case AcceptanceStatuses.CheckPersonalData:
        case AcceptanceStatuses.ChooseDate:
            return t(`${'settlement.acceptance_status.get_keys'}`);
        case AcceptanceStatuses.NeedPrepareDocuments:
        case AcceptanceStatuses.Meeting:
        case AcceptanceStatuses.ResultWithRemarks:
        case AcceptanceStatuses.FailResult:
        case AcceptanceStatuses.SuccessResult:
            return t(`${'settlement.acceptance_status.information'}`);
        case AcceptanceStatuses.Undefined:
        case AcceptanceStatuses.Draft:
        default:
            return ""; 
        }
    };
    
    /**
    * На основе статуса определяем куда ведет кнопка 
    * @param status Статус приемки
    * @param acceptanceId Идентификатор приемки
    */
    const getButtonLink = (status: AcceptanceStatus, acceptanceId: number | undefined): string => {
        if (!acceptanceId) {
            return `${ROUTES.acceptance}/error-link`;
        }

        switch (status) {
        case AcceptanceStatuses.Participant:
            return `${ROUTES.acceptance_id(acceptanceId)}?step=1`;  
        case AcceptanceStatuses.CheckPersonalData: 
            return `${ROUTES.acceptance_id(acceptanceId)}?step=2`; 
        case AcceptanceStatuses.ChooseDate: 
            return `${ROUTES.acceptance_id(acceptanceId)}?step=3`;
        case AcceptanceStatuses.NeedPrepareDocuments:
        case AcceptanceStatuses.Meeting: 
            return `${ROUTES.acceptance_id(acceptanceId)}?step=4`; 
        case AcceptanceStatuses.SuccessResult:
        case AcceptanceStatuses.ResultWithRemarks: 
        case AcceptanceStatuses.FailResult: 
        case AcceptanceStatuses.Cancelled: // вызвать модалку
            return `${ROUTES.acceptance_id(acceptanceId)}?step=5`; 
        case AcceptanceStatuses.Undefined:
        case AcceptanceStatuses.Draft:
        default:
            return `${ROUTES.acceptance}/error-link`;
        }
    };

    /** редирект по клику на адрес из getButtonLink */
    const goToPage = (status: AcceptanceStatus, acceptanceId: number | undefined) => {
        const link = getButtonLink(status, acceptanceId);
        navigateTo(link);
    };

    /**
    * На основе статуса определяем внешний вид кнопки
    * @param status Статус приемки
    */
    function getButtonVariant(status: AcceptanceStatus): "flat" | "elevated" | "text" | "tonal" | "outlined" | "plain" {
        switch (status) {
        case AcceptanceStatuses.NeedPrepareDocuments:
        case AcceptanceStatuses.Meeting:
        case AcceptanceStatuses.ResultWithRemarks:
        case AcceptanceStatuses.FailResult:
        case AcceptanceStatuses.SuccessResult:
            return "outlined";
        case AcceptanceStatuses.Participant:
        case AcceptanceStatuses.CheckPersonalData:
        case AcceptanceStatuses.ChooseDate:
        case AcceptanceStatuses.Cancelled:
        case AcceptanceStatuses.Undefined:
        case AcceptanceStatuses.Draft:
        default:
            return "elevated";
        }
    }

    return {
        getRealtyClientList,
        isButtonShow,
        getButtonText,
        getButtonVariant,
        goToPage,
    };
};